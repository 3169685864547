

















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    apiFaceSheetConfigSetting,
    apiGetFaceSheetConfig,
    apiBetFaceSheetType
} from '@/api/application/express'

@Component({
    components: {}
})
export default class DistributionResultSet extends Vue {
    /** S Data **/

    faceSheetType: Array<Object> = []

    form: any = {
        type: 1,
        key: '',
        secret: '',
        siid: ''
    }

    /** E Data **/

    /** S Methods **/

    async onSubmit() {
        await apiFaceSheetConfigSetting({ ...this.form })
        this.detail()
    }

    async getFaceSheetTypeFunc() {
        const res = await apiBetFaceSheetType({})
        this.faceSheetType = res
    }

    // 详情
    async detail() {
        const res = await apiGetFaceSheetConfig({})
        this.form = res
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.detail()
        this.getFaceSheetTypeFunc()
    }
    /** E Life Cycle **/
}
